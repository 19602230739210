<template>
  <div class="allOrder">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="课程" name="first">
        <div class="list" v-if="list && list.length > 0">
          <div v-for="(item, index) in list" :key="index" class="item">
            <div class="classView" @click="toDetail(item)">
              <div class="box">
                <img class="item-img" :src="item.img" />
                <div class="course_type">
                  <span>知识套餐</span>
                </div>
                <div class="item-centent">
                  <div class="item_name-box">
                    <div class="item-text elp">{{ item.tiitle }}</div>
                  </div>
                  <div class="tips">
                    <!-- 知识套餐 -->
                    <div class="item-tips elp" v-if="item.productLine !== '5'">
                      共{{ item.kechengNum ? item.kechengNum : 0 }}门课
                    </div>
                  </div>
                  <!-- 专属 -->
                  <div class="typeList" v-if="item.productLine == 3 &&
                    item.coufig &&
                    item.coufig.length > 0
                    ">
                    <div class="type-coufig">
                      <img src="@/assets/img/homeSeventh/Group 1632 (1).png" alt="" />
                      <span v-for="(coufig, coufigindex) in item.coufig" :key="coufigindex">
                        {{ coufigindex ? "·" : "" }}{{ coufig }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="price">￥{{ item.price }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="collect_item"
          v-for="(item, index) in list"
          :key="index"
          v-if="list && list.length > 0"
        >
          <div class="item_left">
            <el-image :src="item.img"></el-image>
          </div>
          <div class="item_right">
            <div class="item_name">
              <div class="tip">课程</div>
              <div class="name">{{ item.tiitle }}</div>
            </div>
            <div class="item_bottom">
              <div class="item_price">￥{{ item.price }}</div>
              <div class="item_btn">
                <div class="btn1" @click="cancelClick(item)">取消收藏</div>
                <div class="btn2" @click="toDetail(item)">去查看</div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="nothing" v-if="!list || list.length == 0">
          <div class="nothing-text">暂无收藏内容哦~</div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="教材" name="second">
        <div class="collect_item" v-for="(item, index) in TextbookList" :key="index">
          <div class="item_left">
            <el-image :src="item.faceImg"></el-image>
          </div>
          <div class="item_right">
            <div class="item_name1">
              <div class="tip">教材</div>
              <el-tooltip class="item" effect="dark" :content="item.textbookName" placement="top-start">
                <div class="name">{{ item.textbookName }}</div>
              </el-tooltip>
            </div>
            <div class="item_bottom">
              <div class="item_price">￥{{ item.entrantsPrice }}</div>
              <div class="item_btn">
                <div class="btn1" @click="cancelClick(item)">取消收藏</div>
                <div class="btn2" @click="toDetails(item)">去查看</div>
              </div>
            </div>
          </div>
        </div>
        <div class="nothing" v-if="!TextbookList || TextbookList.length == 0">
          <div class="nothing-text">暂无收藏内容哦~</div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 分页 -->
    <el-pagination v-if="total > 8" class="pagination" :total="total" :current-page="pageNum"
      :page-sizes="[8, 16, 24, 40]" :page-size="pageSize" background layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>

<script>
import { showTypeEnums } from "@/api/emun";
import { Know } from "@/api/know";
import { getStuCollectProductList, getCollectProduct } from "@/api/mykecheng";
let know = new Know();
export default {
  data() {
    return {
      list: [],
      pageSize: 8,
      // 订单 第几页
      pageNum: 1,
      total: 0,
      showTypeEnums: showTypeEnums,
      activeName: "first",
      TextbookList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.total = 0;
      const { total, rows } = await know.getStuCollectClassesProductList({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        type: 1,
      });
      for (const item of rows) {
        const data = [];
        if (item.productLine == "3") {
          if (item.isData == 0) {
            data.push("专属资料");
          }
          if (item.isQuestion == 0) {
            data.push("专属题库");
          }
          if (item.isExamination == 0) {
            data.push("专属考场");
          }
          if (item.isTeaching == 0) {
            data.push("专属教材");
          }
          if (item.isReference == 0) {
            data.push("专属教服");
          }
          item.coufig = data;
        }
      }
      this.list = rows;
      this.total = total;
    },
    async getTextbook() {
      this.total = 0;
      const res = await getStuCollectProductList({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      });
      ;
      this.TextbookList = res.rows;
      this.total = res.total;
    },
    handleClick() {
      if (this.activeName === "first") {
        this.getList();
      } else if (this.activeName === "second") {
        this.getTextbook();
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    toDetail(item) {
      if (item.productLine == 3) {
        const routeUrl = this.$router.resolve({
          path: "/VIP/VIPClassxq",
          query: {
            id: item.id,
          },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        const routeUrl = this.$router.resolve({
          path: "/typeclassxq",
          query: {
            id: item.id,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    toDetails(item) {
      const routeUrl = this.$router.resolve({
        path: `/mall/material`,
        query: {
          id: item.id,
          title: item.textbookName,
          // isPurchase: item.isPurchase,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    async cancelClick(val) {
      const res = await getCollectProduct({
        type: "14",
        typeId: val.id,
        status: "0",
        stuId: localStorage.getItem("userId"),
      });
      this.getTextbook();
    },
  },
};
</script>

<style lang="less" scoped>
.allOrder {
  width: 100%;
  // min-height: 736px;
  // padding-bottom: 60px;
  background-color: #ffffff;
  position: relative;

  /deep/ .el-tabs__nav-wrap {
    padding: 8px 32px 0px;

    .el-tabs__nav {
      // padding-bottom: 8px;
      margin-bottom: 6px;
    }

    .el-tabs__active-bar {
      width: 24px !important;
      height: 2px;
      background: #ff5d51;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      padding-bottom: 3px;
      bottom: -5px;
      left: 3px;
    }

    .el-tabs__item {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999 !important;
    }

    .is-active {
      color: #333333 !important;
    }
  }

  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #eee;
  }

  /deep/.el-tabs__content {
    padding: 10px 38px 0;
    width: 100%;

    .el-tab-pane {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .collect_item {
      width: 560px;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      padding: 20px;

      .item_left {
        width: 114px;
        height: 114px;
        background: #f5f7f9;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-image {
          width: 79px;
          height: 104px;
        }
      }

      .item_right {
        margin-left: 20px;
        width: calc(100% - 114px);

        .item_name,
        .item_name1 {
          display: flex;
          align-items: flex-start;
          margin-top: 10px;

          .name {
            width: 356px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            margin-top: -4px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .tip {
            width: 34px;
            height: 16px;
            background: rgba(255, 93, 81, 0.08);
            border-radius: 3px;
            font-size: 12px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ff5d51;
            text-align: center;
            line-height: 16px;
            margin-right: 6px;
          }
        }

        .item_bottom {
          display: flex;
          align-items: center;
          margin-top: 50px;
          position: relative;

          .item_price {
            font-size: 16px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #f34417;
          }

          .item_btn {
            position: absolute;
            right: 10px;
            bottom: -10px;
            display: flex;
            align-items: center;

            .btn1 {
              width: 110px;
              height: 36px;
              border-radius: 18px 18px 18px 18px;
              opacity: 1;
              border: 1px solid #b9bfc4;
              cursor: pointer;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #777a82;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .btn2 {
              width: 110px;
              height: 36px;
              border-radius: 18px 18px 18px 18px;
              opacity: 1;
              border: 1px solid #ff5d51;
              cursor: pointer;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ff5d51;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  .list {
    width: 100%;
    padding: 20px 0px;

    .classView {
      box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.34);
      min-height: 133px;
      padding: 14px 20px;
      border-radius: 8px;
      cursor: pointer;

      .box {
        display: flex;
        align-items: flex-start;
        position: relative;

        .item-img {
          width: 192px;
          height: 108px;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
        }

        .item-centent {
          height: 108px !important;
        }
      }

      .bottom_box {
        width: 776px;
        background-color: #f9f9f9;
        border-radius: 8px;
        margin-top: 15px;

        .chapter_item {
          height: 110px;
          width: 746px;
          padding: 16px;
          border-bottom: 1px solid #eeeeee;
          display: flex;
          justify-content: space-between;

          .left_box {
            .title_box {
              margin-bottom: 9px;
              display: flex;
              align-items: center;

              .tag {
                display: inline-block;
                padding: 2px 8px;
                height: 22px;
                border-radius: 0 8px 0 8px;
                background: linear-gradient(132deg, #fff3eb 0%, #ffedeb 100%);
                margin-right: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ff6e2d;
                font-size: 12px;
                flex-shrink: 0;

                .tag_img {
                  width: 11px;
                  margin-right: 3px;
                }
              }

              .title {
                color: #333333;
                width: 400px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
              }
            }

            .time {
              color: #aaaaaa;
              font-size: 14px;
              display: flex;
              align-items: center;
              margin-bottom: 19px;

              .time_img {
                width: 14px;
                height: 14px;
                margin-right: 6px;
                flex-shrink: 0;
              }
            }

            .progress_box {
              display: flex;
              align-items: center;

              .progress_bar {
                width: 286px;
                height: 8px;
                background-color: #e5e5e5;
                border-radius: 10px;

                .schedule {
                  background: linear-gradient(90deg, #a5bdff 0%, #2871ff 100%);
                  height: 8px;
                  width: 0;
                  border-radius: 10px;
                }
              }

              .text {
                font-size: 12px;
                color: #2871ff;
                margin-left: 8px;
              }
            }
          }

          .right_box {
            display: flex;
            align-items: center;

            .btn_item {
              width: 80px;
              height: 30px;
              border-radius: 4px;
              border: 1px solid #ff5e51;
              color: #ff5e51;
              font-size: 13px;
              text-align: center;
              line-height: 30px;
              margin-left: 14px;
              cursor: pointer;
            }
          }
        }

        .chapter_item:last-child {
          border-bottom: 0;
        }
      }

      .bottom_boxs {
        background-color: #fff;

        .chapter_item {
          background-color: #f9f9f9;
        }

        .zhang_item {
          display: flex;
          align-items: center;
          padding: 14px 0;

          .time_img {
            display: block;
            width: 14px;
            height: 12px;
            margin-right: 8px;
            flex-shrink: 0;
          }

          .zhang_item_name {
            width: 660px;
          }

          .zhang_item_last {
            flex-shrink: 0;
            width: 40px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #aaaaaa;
            cursor: pointer;

            .iconfont {
              font-size: 12px;
            }
          }
        }

        .chapter_items {
          height: 82px;

          .left_box {
            .title {
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
          }

          .progress_box {
            margin-top: 17px;
          }
        }
      }
    }

    .price {
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: Bold;
      color: #ff5e51;
      line-height: 18px;
      margin-top: 7px;
    }

    .item {
      width: 100%;
      background: #ffffff;
      position: relative;
      margin-bottom: 24px;

      .item-img {
        flex-shrink: 0;
        margin-right: 14px;
      }

      .item-centent {
        width: 100%;

        .item_name-box {
          display: flex;
          align-items: center;

          .item-tips {
            width: 52px;
            height: 20px;
            border-radius: 4px;
            border: 1px solid #ff7b1c;
            font-size: 12px;
            color: #ff7b1c;
            text-align: center;
            line-height: 20px;
            margin-right: 8px;
          }

          .item-text {
            width: 500px;
            height: 16px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
          }
        }

        .tips {
          display: flex;
          align-items: center;
          margin-top: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ff7b1c;
          line-height: 12px;

          .item-tips {
            background-color: #fff2e9;
            padding: 4px 7px;
            border-radius: 4px;
            margin-right: 8px;
          }
        }

        /* 专属 */
        .typeList {
          margin-top: 24px;
          /* position: absolute;
          bottom: 13px; */
          display: flex;
          align-items: center;

          .type-name {
            width: 44px;
            height: 24px;
            // background: #c4c4c4 linear-gradient(134deg, #616f79 0%, #232733 100%);

            background: linear-gradient(117deg, #ffeec9 0%, #f9d996 100%);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffe2b5;
            line-height: 24px;
            text-align: center;
          }

          .type-coufig {
            display: flex;
            align-items: center;
            background: linear-gradient(117deg, #ffeec9 0%, #f9d996 100%);
            border-radius: 14px;

            height: 28px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5f502e;
            line-height: 28px;
            padding: 0 16px;

            img {
              width: 20px;
              // height: 14px;
              margin-right: 4px;
            }
          }
        }
      }
    }

    .course_type {
      width: 56px;
      height: 21px;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 8px 0 8px 0;
      color: #ffffff;
      font-size: 11px;
      text-align: center;
      line-height: 21px;
    }
  }

  .collect {
    width: 1221px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 26px 39px;

    .collect_item {
      width: 560px;
      height: 154px;
      background: #fff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin-bottom: 24px;
      padding: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .item_left {
        width: 114px;
        height: 114px;
        background: #f5f7f9;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        /deep/ .el-image {
          width: 79px;
          height: 104px;
        }
      }

      .item_right {
        width: calc(100% - 114px);
        margin: 10px 0 0 20px;

        .item_name {
          font-size: 16px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }

        .price_btn {
          display: flex;
          margin-top: 52px;
          align-items: center;
          position: relative;

          .price {
            font-size: 16px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 400;
            color: #f34417;

            .price1 {
              font-weight: bold;
              margin-left: 4px;
            }
          }

          .btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 79px;
            position: absolute;
            right: 0;

            .btn1 {
              width: 110px;
              height: 36px;
              border-radius: 18px 18px 18px 18px;
              opacity: 1;
              border: 1px solid #b9bfc4;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #777a82;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 20px;
              cursor: pointer;
            }

            .btn2 {
              width: 110px;
              height: 36px;
              border-radius: 18px 18px 18px 18px;
              opacity: 1;
              border: 1px solid #ff5d51;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ff5d51;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .pagination {
    margin: 0 auto !important;
    padding-bottom: 20px;
    //   position: absolute;
    //   bottom: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
  }
}</style>
<style>.popper__arrow {
  left: 10px !important;
}</style>
